import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import './mainAdminApp.css';
import { selectMainLogin } from '../store/slices/mainAdminSlices/mainAdminLogin/mainAdminSlice';
import { mainLogin, mainParkList, mainParkReport } from '../store/slices/mainAdminSlices/mainAdminLogin/mainAdminLogin';
import getPercentage from "../assets/percentage";

function MainAdminApp() {
    const mainData = useSelector(selectMainLogin);
    const dispatch = useDispatch();
    const [sum, setSum] = useState(0);
    const [daily, setDaily] = useState(0)
    const [countdown, setCountdown] = useState(10); // Countdown state for 10 seconds
    const [mainLoginInputs, setMainLoginInputs] = useState({
        username: 'admin',
        password: 'adminiparol'
    });


    const getToday = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(now.getDate()).padStart(2, '0'); // Current day of the month
        return `${year}-${month}-${day}`;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMainLoginInputs({
            ...mainLoginInputs,
            [name]: value
        });
    };

    const handleMainLogin = (e) => {
        e.preventDefault();
        const { username, password } = mainLoginInputs;
        dispatch(mainLogin({ username, password }));
    };

    useEffect(() => {
        if (mainData.isLoggedIn) {
            dispatch(mainParkList());
        }
    }, [mainData.isLoggedIn, dispatch]);

    useEffect(() => {
        // Fetch data immediately on mount or after login
        const fetchData = () => {
            console.log("mainData after update:", mainData);

            if (mainData.data?.parks?.length > 0) {
                for (let i = 1; i < mainData.data?.parks?.length; i++) {
                    dispatch(mainParkReport({ park_id: mainData.data.parks[i].park_id }));
                }
            }

            let newDaily = 0;
            let newSum = 0;
            for (let i = 0; i < mainData?.reportData?.length; i++) {
                newSum += mainData.reportData[i].ok_total || 0;
                newDaily += mainData.reportData[i].days[getToday()]
            }
            setSum(newSum);
            setDaily(newDaily);
            setCountdown(10); // Reset countdown every 10 seconds
        };


        const interval = setInterval(fetchData, 2000); // Continue fetching every 10 seconds

        return () => clearInterval(interval);
    }, [mainData, dispatch]);

    useEffect(() => {
        // Countdown timer that decreases every second
        const countdownInterval = setInterval(() => {
            setCountdown((prev) => (prev > 0 ? prev - 1 : 10)); // Reset countdown if it reaches 0
        }, 1000);

        return () => clearInterval(countdownInterval);
    }, []);
    const isPlus = () =>{
        return (Math.round((Math.round(0.3 / 100 * sum) - Math.round(0.3 / 100 * sum) / 20)) - 10400 - 2200 - 3500 - 10000) >=0
    }
    return (
        <div className="MainAdminApp">
            <h1>{(0.3 / 100 * daily).toFixed(2)}</h1>
            { !mainData.isLoggedIn ? (
                <form onSubmit={handleMainLogin}>
                    <input
                        type="text"
                        name="username"
                        placeholder="Username"
                        value={mainLoginInputs.username}
                        onChange={handleInputChange}
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={mainLoginInputs.password}
                        onChange={handleInputChange}
                    />
                    <button type="submit">Submit</button>
                </form>): (<></>)
            }

            {mainData?.data?.parks && mainData.reportData ? (
                <div className="mainParkList">
                    <div className="parkPercents">
                        {mainData.reportData?.map((el, index) => (
                            <div className='parkInfo' key={index}>
                                <span>{mainData.data?.parks?.find(park => park.park_id === el.park_id)?.name}</span>
                                <span>{Math.round(getPercentage(mainData.data?.parks?.find(park => park.park_id === el.park_id)?.name) / 100 * el.ok_total)}</span>
                                <span>Active {el.active_count}</span>
                            </div>
                        ))}
                        <hr />
                        <div className="infoContainer">
                            <div className="MoneyInfoContainer">
                                <div className='MoneyInfo impor'>
                                    <span>Today</span>
                                    <span>{Math.round(0.3 / 100 * daily)}</span>
                                </div>
                                <div className='MoneyInfo impo'>
                                    <span>DiamondPay</span>
                                    <span>{Math.round(0.3 / 100 * sum)}</span>
                                </div>
                                <div className='MoneyInfo'>
                                    <span>Tax 5%</span>
                                    <span>{Math.round(0.3 / 100 * sum) / 20}</span>
                                </div>
                                <div className='MoneyInfo'>
                                    <span>Income</span>
                                    <span>{Math.round(0.3 / 100 * sum) - Math.round(0.3 / 100 * sum) / 20}</span>
                                </div>
                                <div className={isPlus() ? 'MoneyInfo plus' : 'MoneyInfo impo'}>
                                    <span>Earnings</span>
                                    <span>{Math.round((Math.round(0.3 / 100 * sum) - 13500))}</span>
                                </div>
                                <div className='MoneyInfo'>
                                    <span>StartUp</span>
                                    <span>{(Math.round(0.3 / 100 * sum) - Math.round(0.3 / 100 * sum) / 20) - 107900}</span>
                                </div>
                            </div>


                            {/*// ---------------------*/}


                            <div className="ParkContentInfoContainer">
                                {mainData.reportData?.map((el, index) => (
                                    <div className='MoneyInfo' key={index}>
                                        <span>{mainData.data?.parks?.find(park => park.park_id === el.park_id)?.name}</span>
                                        <span>{Math.round(getPercentage(mainData.data?.parks?.find(park => park.park_id === el.park_id)?.name) / 100 * el.days[getToday()])}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading parks...</p>
            )}
        </div>
    );
}

export default MainAdminApp;
