import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';

export const fetchDriverList = createAsyncThunk(
    'driverList/fetchDriverList',
    async function({park_id, limit, offset,query = null}) {
            const response = await axios.get(`https://api.diamondpay.am/api/park/driver/list`,{
                params:{
                    park_id,
                    limit,
                    offset,
                    query
                    
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = response.data;
            return data;
        }
);

