/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainInfo from './components/MainInfo';
import Settings from './components/Settings';
import Transfer from './components/Transfer';
import DriverHeader from './components/DriverHeader';
import './driverApp.css'
import Login from './components/Login/Login';
import DriverOrderList from './components/DriverOrderList';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDriver } from '../store/slices/deiverSlice/deiverAPI';
import { useEffect, useState } from 'react';
import { selectDriver,  } from '../store/slices/deiverSlice/deiverSlice';
import Loading from '../sharedComponents/Loading/Loading';


function DriverApp() {
  const driver = useSelector(selectDriver);
  const isLogIn = driver.islogin; 
  const dispatch = useDispatch()
  const [newAmount, setNewAmount] = useState(0)
  const [anim, setAnim] = useState(false)
  const [showToaster, setShowToaster] = useState(true );


  const handleShowToaster = (state) => {
    setShowToaster(state);
  };

  useEffect(() => {
      dispatch(fetchDriver(''))
  }, [isLogIn]);

useEffect(() => {
    // Refetch balance when page becomes visible
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
          dispatch(fetchDriver('')) // Fetch new balance when app is visible
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <div className="DriverApp">

      <Loading show={driver?.loading} />
      <Routes>
        <Route path='/driver' element={
          isLogIn ? 
          <>
            <DriverHeader />
            <MainInfo newAmount={newAmount} anim={anim} />
            <Transfer setNewAmount={setNewAmount} setAnim ={setAnim} />
          </> 
          :
          <Login />
        } />
        <Route path='/settings' element={isLogIn? <Settings />: <Login/>} />
        <Route path='/orders' element={isLogIn?<DriverOrderList/>: <Login/>} />
      </Routes>
    </div>
  );
}

export default DriverApp;