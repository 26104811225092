import { useEffect, useState } from 'react';
import CircularProgress from '../../../sharedComponents/CircularProgres/CircularProgres';
import LiveChart from '../../../sharedComponents/LiveChart/LiveChart';
import './Dashboard.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard } from '../../../store/slices/adminSlices/adminDashboardSlice/adminDashboardSlice';
import { fetchDashboard } from '../../../store/slices/adminSlices/adminDashboardSlice/adminDashboardAPI';
import PageHeader from '../PageHeader';
import { checkIsLoggedIn } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { selectAdminLoading, selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { useNavigate } from 'react-router-dom';
import getPercentage from "../../../assets/percentage";

const Dashboard = () => {
    const dispatch = useDispatch();
    const reportData = useSelector(selectDashboard);
    const report = reportData?.data;
    const adminLogin = useSelector(selectAdminLogin);
    const loading = useSelector(selectAdminLoading);
    const navigate = useNavigate();
    const [chart, setChart] = useState([]);
    const [dashboardFilterValues, setDashboardFilterValues] = useState({
        dateRange: [],
        dateRangeDates: [],
        status: [],
        inputVal: '',
        driver_id: ''
    });


    const getFirstDayOfCurrentMonth = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = '01';
        return `${year}-${month}-${day}`;
    };
    
    useEffect(() => {
        if (!report && adminLogin?.park_id) {
            dispatch(fetchDashboard({ park_id: adminLogin.park_id, start: getFirstDayOfCurrentMonth() }));
        }
    }, [report, dispatch, adminLogin]);

    useEffect(() => {
        dispatch(checkIsLoggedIn());
    }, []);

    useEffect(() => {
        if (!loading) {
            if (!adminLogin) {
                navigate('/admin/login');
            } else {
                navigate('/admin/dashboard');
            }
        }
    }, [loading]);

    useEffect(() => {
        if (report) {
            const chartData = Object.keys(report.days).map((el) => ({
                day: `${parseInt(el.split('-')[2], 10)}`,
                price: report.days[el],
            }));
            setChart(chartData);
        }
    }, [report]);

    function formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return 0;
    }

    function graphPercent(x, y) {
        if (x && y) {
            return ((y / x) * 100).toFixed(1);
        }
        return 0;
    }

    const percent = report ? Math.round((report?.ok_total * getPercentage(adminLogin?.name)) / 100) : 0;

    return (
        <div className="Dashboard">
            <PageHeader
                heading="Հաշվետվություն"
                hasFilter
                page={'dashboard'}
                filterValues={dashboardFilterValues}
                setFilterValues={setDashboardFilterValues}
            />

            <div className="DashboardMainInfo">
                <div className="DashboardBadges">
                    <h3>Կատարված</h3>
                    <span>֏{formatNumber(report?.total) || 0}</span>
                    <span className="badgesSecondaryInfo">Քանակ։ {formatNumber(report?.amount) || 0}</span>
                    <i className="fa-regular fa-circle-check badgesIcons"></i>
                </div>
                <div className="DashboardBadges">
                    <h3>Փոխանցված</h3>
                    <span>֏{formatNumber(report?.ok_total) || 0}</span>
                    <span className="badgesSecondaryInfo">Քանակ։ {formatNumber(report?.ok_amount) || 0}</span>
                    <i className="fa-solid fa-money-bill-transfer badgesIcons"></i>
                </div>
                <div className="DashboardBadges">
                    <h3>Diamond Pay</h3>
                    <span>֏{formatNumber(percent) || 0}</span>
                    <i className="fa-solid fa-percent badgesIcons"></i>
                </div>
                <div className="DashboardBadges">
                    <h3>Ակտիվ վարորդներ</h3>
                    <span className="badgesSecondaryInfo activeDrivers">{report?.active_count}</span>
                    <i className="fa-solid fa-bolt badgesIcons"></i>
                </div>
            </div>

            <div className="DashboardGraphs">
                <div className="GeneralStatistics">
                    <LiveChart chartData={chart} />
                </div>
                <div className="RatioGraph">
                    <CircularProgress
                        percent={graphPercent(report?.ok_amount, (report?.amount - report?.ok_amount)) || 0}
                        text=""
                        borderR=""
                    />
                    <div className="ProgressInfo">
                        <div className="ProgressInfoContainer">
                            <div className="InfoColors1">
                                <span className="ProgressInfoColor1"></span>
                                <div className="InfoColorsInfo">
                                    <span>Փոխանցված</span>
                                    <span className='InfoAmount'>Քանակ։ {report?.ok_amount}</span>
                                </div>
                            </div>
                            <div className="InfoColors2">
                                <span className="ProgressInfoColor2"></span>
                                <div className="InfoColorsInfo">
                                    <span>Չփոխանցված</span>
                                    <span className='InfoAmount'>Քանակ։ {report?.amount - report?.ok_amount}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
