import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';

function generateToken() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';

    for (let i = 0; i < 17; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        token += characters[randomIndex];
    }

    return token;
}
//81f90dbe007d4364ad88c56f77319695
export const sendAdminTransfer = createAsyncThunk(
    'adminTransfer/sendAdminTransfer',
    async function({amount, driver_id,park_id}) {
        
        if (amount) {
            const response = await axios.post(`https://api.diamondpay.am/api/park/transfer`, {
                park_id,
                driver_id,
                amount,
                token: generateToken(),
                comment: 'Diamond Pay'
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = response.data;
            return data;
        }
    }
);

export const fetchQueryDrivers = createAsyncThunk(
    'driverList/fetchQueryDrivers',
    async function({park_id, limit, offset, query = null}) {
            const response = await axios.get(`https://api.diamondpay.am/api/park/driver/list`,{
                params:{
                    park_id,
                    limit,
                    offset,
                    query
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = response.data;
            return data;
        }
);
