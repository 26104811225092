import React, { useEffect, useState } from 'react';
import './DriversPage.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Select, MenuItem, FormControl, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDriverList } from '../../../store/slices/adminSlices/adminDriverList/adminDriverListApi';
import { selectDriverList } from '../../../store/slices/adminSlices/adminDriverList/adminDriverList';
import PageHeader from '../PageHeader';
import { checkIsLoggedIn } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { selectAdminLoading, selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const DriversPage = () => {
  const dispatch = useDispatch();
  const driverList = useSelector(selectDriverList);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const adminLogin = useSelector(selectAdminLogin);
  const loading = useSelector(selectAdminLoading);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = driverList?.drivers;
  const numberOfPages = Math.ceil(driverList?.amount / rowsPerPage);

  useEffect(() => {
    const offset = page * rowsPerPage;
    dispatch(fetchDriverList({ park_id: adminLogin?.park_id, limit: rowsPerPage, offset }));
  }, [page, rowsPerPage, dispatch, adminLogin]);

  useEffect(() => {
    dispatch(checkIsLoggedIn());
  }, []);

  useEffect(() => {
    if (!loading) {
      if (!adminLogin) {
        navigate('/admin/login');
      } else {
        navigate('/admin/drivers');
      }
    }
  }, [loading]);

  const renderPagination = () => {
    const pagesToShow = isSmallScreen ? 1: 4; // Show fewer pages on small screens
    const startPage = Math.max(0, page - Math.floor(pagesToShow / 2));
    const endPage = Math.min(startPage + pagesToShow - 1, numberOfPages - 1);
  
    const pages = [];
  
    if (startPage > 0) {
      pages.push(
        <Button key={0} onClick={() => handleChangePage(0)} variant="outlined">
          1
        </Button>,
        startPage > 1 && <Typography key="start-ellipsis" variant="body2">...</Typography>
      );
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          onClick={() => handleChangePage(i)}
          variant="outlined"
          sx={{
            mx: 0.5,
            fontSize: isSmallScreen ? '12px' : 'inherit', // Smaller font size on small screens
            color: i === page ? 'white' : 'inherit',
            borderColor: i === page ? '#FF5C00' : 'black',
            backgroundColor: i === page ? '#FF5C00' : 'transparent',
          }}
        >
          {i + 1}
        </Button>
      );
    }
  
    if (endPage < numberOfPages - 1) {
      pages.push(
        endPage < numberOfPages - 2 && <Typography key="end-ellipsis" variant="body2">...</Typography>,
        <Button key={numberOfPages - 1} onClick={() => handleChangePage(numberOfPages - 1)} variant="outlined">
          {numberOfPages}
        </Button>
      );
    }
  
    return pages;
  };
  

  return (
    <>
      <TableContainer component={Paper} className="TableContainer">
        <Box display="flex" alignItems="center" justifyContent="space-between" px={2} mt={2}>
        {!isSmallScreen && ( // Conditionally render this part based on screen size
          <Box display="flex" alignItems="center">
            <Typography variant="body1" sx={{ marginRight: 1 }}>Items per page:</Typography>
            <FormControl variant="outlined" size="small">
              <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
              <Box display="flex" alignItems="center" justifyContent="center" flexWrap="nowrap">
                <Button onClick={() => handleChangePage(page - 1)} disabled={page === 0} sx={{ color: '#FF5C00' }}>&lt;</Button>
                {renderPagination()}
                <Button onClick={() => handleChangePage(page + 1)} disabled={page >= numberOfPages - 1} sx={{ color: '#FF5C00' }}>&gt;</Button>
              </Box>
            </Box>
            
        {isSmallScreen ? (
          <div>
            <div className="CardContainer">
              {paginatedData?.map((driver, index) => (
                <div key={index} className="DriverCard">
                  <h3>{driver.name}</h3>
                  <div className="DriverCardInfo">
                    <div className="DriverCardItem">
                      <span className="DriverCardItemLabel">Balance:</span>
                      <span>{driver.balance}</span>
                    </div>
                    <div className="DriverCardItem">
                      <span className="DriverCardItemLabel">Idram:</span>
                      <span>{driver.idram}</span>
                    </div>
                    <div className="DriverCardItem">
                      <span className="DriverCardItemLabel">Percentage:</span>
                      <span>{driver.percentage * 100}%</span>
                    </div>
                    <div className="DriverCardItem">
                      <span className="DriverCardItemLabel">Phone:</span>
                      <span>{driver.phone}</span>
                    </div>
                    <div className="DriverCardItem">
                      <span className="DriverCardItemLabel">Mode:</span>
                      <span>{driver.transfer_allowed ? "Auto" : "Manual"}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Idram</TableCell>
                  <TableCell>Percentage</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Mode</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData?.map((driver, index) => (
                  <TableRow key={index}>
                    <TableCell>{driver.name}</TableCell>
                    <TableCell>{driver.balance}</TableCell>
                    <TableCell>{driver.idram}</TableCell>
                    <TableCell>{driver.percentage * 100}%</TableCell>
                    <TableCell>{driver.phone}</TableCell>
                    <TableCell>{driver.transfer_allowed ? "Auto" : "Manual"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
         <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
              <Button onClick={() => handleChangePage(page - 1)} disabled={page === 0} sx={{ color: '#FF5C00' }}>&lt;</Button>
              {renderPagination()}
              <Button onClick={() => handleChangePage(page + 1)} disabled={page >= numberOfPages - 1} sx={{ color: '#FF5C00' }}>&gt;</Button>
            </Box>
      </TableContainer>
    </>
    
  );
};

export default DriversPage;
