import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Paper } from '@mui/material';

const LiveChart = ({chartData}) => {
 

  const formatYAxisTicks = (value) => {
    return value >= 1000000 ? `${(value / 1000000).toFixed(1)}M` : value.toString();
  };

  return (
    <Paper  sx={{ padding: 0, border: 'none', width: '100%', height: '100%' }}>
      <ResponsiveContainer>
        <LineChart data={chartData} margin={{ top: 10, right: 30, left: 30, bottom: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" label={{ value: "Օր", position: "insideBottomRight", offset: -5 }} />
          <YAxis
            domain={[0, 'dataMax + 100000']}
            label={{ value: "Գումար", angle: -90, position: "insideLeft", offset: -10 }}
            tickFormatter={formatYAxisTicks}
            tick={{ fontSize: 12 }}
          />
          <Tooltip />
          <Line type="monotone" dataKey="price" stroke="#FF5C00" strokeWidth={2} dot={true} />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default LiveChart;
