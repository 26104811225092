import React, { useState, useEffect, useRef } from 'react';
import './PageHeader.css';
import DateRangePicker from '../../../sharedComponents/DateRangePicker/DateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboard } from '../../../store/slices/adminSlices/adminDashboardSlice/adminDashboardAPI';
import { selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { fetchQueryDrivers } from '../../../store/slices/adminSlices/adminTransferSlice/adminTransferAPI';
import { clearDrivers, selectAdminTransfer } from '../../../store/slices/adminSlices/adminTransferSlice/adminTransferSlice';
import { fetchAdminOrderList } from '../../../store/slices/adminSlices/adminOrderList/adminOrderAPI';

const PageHeader = ({ heading, hasFilter, page, filterValues, setFilterValues }) => {
    const [isFilterActive, setFilterActive] = useState(false);
    const filterRef = useRef(null);
    const overlayRef = useRef(null);
    const pageHeaderRef = useRef(null);
    const dateRangePickerRef = useRef(null);
    const queryDataDisplayRef = useRef(null);
    const dispatch = useDispatch();
    const queryData = useSelector(selectAdminTransfer);
    const adminLogin = useSelector(selectAdminLogin);

    const getFirstDayOfCurrentMonth = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = '01';
        return `${year}-${month}-${day}`;
    };

    const checkStatus = (stat) => {
        if (stat === 'all' && (!filterValues.status.length || filterValues.status.length === 5)) {
            return true;
        }
        return filterValues.status.includes(stat);
    };

    const handleStatusChange = (stat) => {
        setFilterValues((prevState) => {
            if (stat === 'all') {
                return {
                    ...prevState,
                    status: []
                };
            }
            const isStatusSelected = prevState.status.includes(stat);
            const updatedStatus = isStatusSelected
                ? prevState.status.filter((s) => s !== stat)
                : [...prevState.status, stat];

            return {
                ...prevState,
                status: updatedStatus
            };
        });
    };

    const toggleFilter = () => {
        setFilterActive(!isFilterActive);
    };

    const handleOverlayClick = () => {
        setFilterActive(false);
    };

    const handleClickOutside = (event) => {
        if (
            filterRef.current &&
            !filterRef.current.contains(event.target) &&
            pageHeaderRef.current &&
            !pageHeaderRef.current.contains(event.target) &&
            dateRangePickerRef.current &&
            !dateRangePickerRef.current.contains(event.target)
        ) {
            setFilterActive(false);
        }
    };

    const applyFilters = () => {
        if (page === 'dashboard') {
            dispatch(fetchDashboard({ park_id: adminLogin.park_id, start: filterValues.dateRange[0], end: filterValues.dateRange[1], status: filterValues.status, driver_id: filterValues.driver_id }));
        } else if (page === 'transactions') {
            if (filterValues.dateRange[0]) {
                console.log('if');
                
                dispatch(fetchAdminOrderList({
                    park_id: adminLogin?.park_id,
                    limit: 20,
                    offset: 0,
                    start: filterValues.dateRange[0],
                    end: filterValues.dateRange[1],
                    driver_id: filterValues.driver_id,
                    status: filterValues.status
                }));
            } else {
                console.log('else');
                
                dispatch(fetchAdminOrderList({
                    park_id: adminLogin?.park_id,
                    limit: 20,
                    offset: 0,
                    start: getFirstDayOfCurrentMonth(),
                    driver_id: filterValues.driver_id,
                    status: filterValues.status
                }));
            }
            
        }
    };

    const clearFilters = () => {
        if (page === 'dashboard') {
            dispatch(fetchDashboard({ park_id: adminLogin.park_id, start: getFirstDayOfCurrentMonth() }));
        } else if (page === 'transactions') {
            dispatch(fetchAdminOrderList({ park_id: adminLogin.park_id, start: getFirstDayOfCurrentMonth() }));
        }
        setFilterValues({ status: [], dateRange: [], inputVal: '', driver_id: '' });
    };

    const handleDriverName = (driver, name) => {
        if (driver !== name) {
            setFilterValues({
                ...filterValues,
                inputVal: name,
                driver_id: driver
            });
        }
    };

    useEffect(() => {
        if (queryData.data?.drivers[0] && filterValues.inputVal === queryData.data?.drivers[0].name) {
            handleDriverName(queryData.data?.drivers[0].name, queryData.data?.drivers[0].name);
        } else if (filterValues.inputVal.length >= 4 && filterValues.inputVal !== '+374' ) {
            dispatch(fetchQueryDrivers({ park_id: adminLogin?.park_id, limit: 10, offset: 0, query: filterValues.inputVal }));
        } else if (!filterValues.inputVal || filterValues.inputVal === '+374') {
            dispatch(clearDrivers());
        }
    }, [filterValues.inputVal, adminLogin]);

    useEffect(() => {
        const handleClickOutsideQuery = (event) => {
            if (
                queryDataDisplayRef.current &&
                !queryDataDisplayRef.current.contains(event.target)
            ) {
                dispatch(clearDrivers());
            }
        };

        document.addEventListener('click', handleClickOutsideQuery);
        return () => {
            document.removeEventListener('click', handleClickOutsideQuery);
        };
    }, [dispatch]);

    return (
        <div className='PageHeader' ref={pageHeaderRef}>
            <h2>{heading}</h2>
            {hasFilter && (
                <button className='FilterBtn' onClick={toggleFilter}>
                    <i className="fa-solid fa-filter"></i> Ֆիլտրեր
                </button>
            )}

            {isFilterActive && (
                <>
                    <div className="overlay filterDim" ref={overlayRef} onClick={handleOverlayClick}></div>

                    <div className={`filterSection ${isFilterActive ? 'activeFilterSection' : ''}`} ref={filterRef}>
                        <div className="filterSectionContainer">
                            <h2>Ֆիլտրեր</h2>
                            <div className="filterSectionsContainer">
                                <h3>Անուն, հեռախոսահամար, Idram</h3>
                                <input
                                    value={filterValues.inputVal}
                                    onChange={(e) => setFilterValues({ ...filterValues, inputVal: e.target.value })}
                                    className='filterSectionInput'
                                    type="text"
                                />
                                {(queryData.data && filterValues.inputVal !== queryData.data?.drivers[0]) && (
                                    <div
                                        className="queryDataDisplay"
                                        ref={queryDataDisplayRef}
                                    >
                                        {queryData.data?.drivers?.map((el) => (
                                            <span
                                                onClick={() => {
                                                    handleDriverName(el.driver_id, el.name);
                                                    dispatch(clearDrivers());
                                                }}
                                                key={el.name}
                                            >
                                                {el.name}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="filterSectionsContainer">
                                <h3>Ամսաթիվ</h3>
                                <div ref={dateRangePickerRef}>
                                    <DateRangePicker filterInputs={filterValues} setFilterInputs={setFilterValues} />
                                </div>
                            </div>
                            <div className="filterSectionsContainer">
                                <h3>Կարգավիճակ</h3>
                                <div className="StatusFilterContainer">
                                    {['all', 'ok', 'pending', 'yandex_balance','hand'].map((stat) => (
                                        <div
                                            key={stat}
                                            onClick={() => handleStatusChange(stat)}
                                            className={checkStatus(stat) ? 'filterStatus activeStatus' : 'filterStatus'}
                                        >
                                            <span>
                                                {
                                                    stat === 'all' ? 'Բոլորը' :
                                                        stat === 'ok' ? 'Փոխանցված' :
                                                            stat === 'yandex_balance' ? 'Անբավարար միջոցներ' :
                                                                stat === 'pending' ? 'Ընթացքում' :
                                                                    stat === 'hand' && 'Ձեռքով ռեժիմ'
                                                }
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="filterBtns">
                            <button className='filterBtnApply' onClick={applyFilters}>Կիրառել</button>
                            <button className='filterBtnClear' onClick={clearFilters}>Մաքրել</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PageHeader;
