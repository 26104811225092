import './AdminContactUs.css';
import { useDispatch, useSelector } from "react-redux";
import { selectAdminLoading, selectAdminLogin } from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { checkIsLoggedIn } from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";

const AdminContactUs = () => {
    const dispatch = useDispatch();
    const adminLogin = useSelector(selectAdminLogin);
    const loading = useSelector(selectAdminLoading);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(checkIsLoggedIn());
    }, [dispatch]);

    useEffect(() => {
        if (!loading) {
            if (!adminLogin) {
                navigate('/admin/login');
            }
        }
    }, [loading, adminLogin, navigate]);

    return (
        <div className="AdminContactUs">
            <div className="contact-container">
                <div className="contact-details">
                    <h1>Կապ մեզ հետ</h1>
                    <div className="contact-info">
                        <div className="contact-item">
                            <i className="fas fa-phone-alt"></i>
                            <span><strong>Հեռախոսսահամար։</strong> <a href="tel:+37444240344">+374 44 24 03 44</a></span>
                        </div>
                        <div className="contact-item">
                            <i className="fas fa-map-marker-alt"></i>
                            <span><strong>Հասցե:</strong> Ռուբինյանց 7/1</span>
                        </div>
                        <div className="contact-item">
                            <i className="fas fa-envelope"></i>
                            <span><strong>Էլ․ փոստ:</strong> paydiamond@yandex.com</span>
                        </div>
                    </div>
                </div>
                <div className="map-container">
                    <iframe
                        title="Map to Rubinyants 7/1"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.269014589055!2d44.523831515281725!3d40.183426379391316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4041a83d4e4c663d%3A0x9f3b7d1cb67b6a85!2sRubinyants%207%2F1%2C%20Yerevan%2C%20Armenia!5e0!3m2!1sen!2s!4v1698255589098!5m2!1sen!2s"
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default AdminContactUs;
