/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import './DriverOrderList.css'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDriversOrders } from '../../../store/slices/driverOrdersSlice/driverOrdersSlice';
import { fetchDriverOrders } from '../../../store/slices/driverOrdersSlice/driverOrdersAPI';
import { selectDriver } from '../../../store/slices/deiverSlice/deiverSlice';

const DriverOrderList = () => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${day}-${month}-${year}/${hours}:${minutes}`;
    };
      const orderList = useSelector(selectDriversOrders)
      const driver = useSelector(selectDriver)
      const dispatch = useDispatch()
    useEffect(() => {
        if (!orderList) {
            dispatch(fetchDriverOrders(driver?.data?.driver_id))
        }
    }, []);
      
      
    return (
        <div className='DriverOrderList'>
            <div className="OrderListHeader">
                <Link className='HomeLink' to={'/driver'}><i className='fas fa-arrow-left'></i></Link>
                <span>Գործարքներ</span>
                <Link className='HomeLink' to={'/settings'}><i className='fa fa-cog'></i></Link>
            </div>
            <div className="DriverOrderListContainer">
            {orderList?.orders?.map((el) => (
                <div key={el.id} className={el.status === "ok" ? 'DriverOrderListItemSuccess DriverOrderListItem' :  'DriverOrderListItem'}>
                    <span>{formatDate(el.ended)}</span>
                    <span>{el.status === "ok" ? 'Փոխանցված' : 'Չեղարկված'}</span>
                    <span>֏{el.price}</span> 
                </div>
            ))}
            </div>      
        </div>
    );
}

export default DriverOrderList;
