import { createSlice } from "@reduxjs/toolkit";
import { fetchDriverOrders } from "./driverOrdersAPI";

const DriverOrdersSlice = createSlice({
    name: 'driverOrders',
    initialState: [],
    reducers:{  
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDriverOrders.fulfilled, (state, {payload}) => {
                return payload; 
            });
    },
})

export const selectDriversOrders = state => state.driverOrders

// export const { setLoginInput,setLogin } = loginInputSlice.actions

export const driverOrdersReducer = DriverOrdersSlice.reducer