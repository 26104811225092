import { createSlice } from "@reduxjs/toolkit";
import { fetchQueryDrivers, sendAdminTransfer } from "./adminTransferAPI";

const adminTransferSlice = createSlice({
    name: 'adminTransfer',
    initialState: {
        status: '',
        data: null
    },
    reducers:{
        clearDrivers: (state, {payload}) => { 
            return {
                ...state,
                data: null
            }; 
          }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendAdminTransfer.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    status: 'success'
                }
            })
            .addCase(sendAdminTransfer.rejected, (state, {payload}) => {
                return {
                    ...state,
                    status: 'fail'
                }
            })
            .addCase(fetchQueryDrivers.fulfilled, (state, {payload}) =>{
                return {
                    ...state,
                    data: {...payload}
                }
            })
    },
});
export const selectAdminTransfer = (state) => state.adminTransfer
export const selectAdminTransferStatus = (state) => state.adminTransfer.status
export const {clearDrivers} = adminTransferSlice.actions
export const adminTransferReducer = adminTransferSlice.reducer;
