import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axiosInstance'; 

export const checkDriver = createAsyncThunk(
    'loginInput/checkDriver',
    async function(phone) {
            
            const response = await axios.get(`https://api.diamondpay.am/api/driver/check`, {
                params:{
                    phone
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = response.data;
            return data;
        }
);



export const registerDriver = createAsyncThunk(
    'loginInput/registerDriver',
    async function({ phone, password }, { rejectWithValue }) {
        try {
            if (password.length === 0) {
                throw new Error('Password must be longer than 4 characters.');
            }

            const response = await axios.post(`https://api.diamondpay.am/api/driver/register`, {
                phone,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const data = response.data;
            console.log(data);
            
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const sendPassword = createAsyncThunk(
    'loginInput/sendPassword',
    async function({username, password}) {
        
            const response = await axios.post(`https://api.diamondpay.am/api/login`, {
                    username,
                    password
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = response.data;
            return data;
        }
);
export const logout = createAsyncThunk(
    'loginInput/logout',
    async function() {
             await axios.get(`https://api.diamondpay.am/api/logout`)
        }
);