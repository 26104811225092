import { createSlice } from "@reduxjs/toolkit";
import { fetchDashboard } from "./adminDashboardAPI";

const adminDashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        isLoading: false,
        data: null
    },
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboard.fulfilled, (state, {payload}) => {
                return { 
                    ...state,
                    data: {...payload},
                    isLoading: false 
                };
            })
            .addCase(fetchDashboard.pending,(state, {payload})=>{
                return {
                    ...state,
                    isLoading: true
                }
            })
    },
});

export const selectDashboard = (state) => state.dashboard;
export const selectChartData = (state) => state.dashboard?.days;
export const adminDashboardReducer = adminDashboardSlice.reducer;
