import { createSlice } from "@reduxjs/toolkit";
import { checkDriver, registerDriver, sendPassword } from "./loginInputAPI";

const loginInputSlice = createSlice({
    name: 'loginInput',
    initialState: {
        phoneNumber: '+374',
        isDriver: '',
        isLoggedin: false,
        error: '',
        loading: false
    },
    reducers:{
        setLoginInput(state, { payload }) {
            let phoneNumber = payload.phoneNumber;  
            
            if (!phoneNumber.startsWith('+374')) {
                phoneNumber = '+374' + phoneNumber.replace(/^\+374/, '');
            }
        
            if (phoneNumber.length <= 4) {
                phoneNumber = '+374';
            }
        
            if (/^\+?(\d+[ ]?)*\d*$/.test(phoneNumber)) {
                return {
                    ...state,
                    phoneNumber: phoneNumber  
                };
            }
        
            return state;
        },
        
        
        setLogin(state, {payload}){
            return {
                ...state,
                phoneNumber: payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(checkDriver.pending, (state) => {
            state.loading = true;   // Set loading to true when the request is pending
            state.error = '';       // Clear any existing error
        })
        .addCase(checkDriver.fulfilled, (state, { payload }) => {
            state.loading = false;  // Set loading to false when the request is fulfilled
            state.isDriver = payload; 
        })
        .addCase(checkDriver.rejected, (state, { error }) => {
            state.loading = false;  // Set loading to false when the request is rejected
            state.error = error.message || 'Failed to check driver';  // Set the error message
        })
            .addCase(registerDriver.fulfilled, (state, {payload}) =>{
                return {
                    ...state,
                    isLoggedin: payload === "success" ? true : false
                }
            })

            .addCase(sendPassword.fulfilled, (state, {payload}) =>{
                if(payload === 'success') window.location.reload()
                return {
                    ...state,
                }
            })
            .addCase(sendPassword.rejected, (state, {payload}, error) =>{
                
                return {
                    ...state,
                    error: 'wrong pass'
                }   
             
            })
    },
})

export const selectLoginInput = state => state.loginInput

export const { setLoginInput,setLogin } = loginInputSlice.actions

export const loginInputReducer = loginInputSlice.reducer