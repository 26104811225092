import { createSlice } from "@reduxjs/toolkit";
import {adminLogout, changePassword, checkIsLoggedIn, register, sendAdminPassword} from "./adminLoginAPI";

const adminLoginSlice = createSlice({
    name: 'adminLogin',
    initialState: {
        loading: true,
        data: null,
        passwordError: false
    },
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(sendAdminPassword.fulfilled, (state, {payload}) => {
                return {
                    loading: false,
                    data: {
                        ...payload
                    },
                    passwordError: false
                }
            })
            .addCase(sendAdminPassword.pending, (state, {payload}) => {
                return {
                    ...state,
                }
            })
            .addCase(sendAdminPassword.rejected, (state, {payload}) => {
                console.log('rejected')
                return{
                    ...state,
                    loading: false,
                    passwordError: true
                }
            })
            .addCase(adminLogout.fulfilled, (state, {payload})=>{
                return {
                    ...state,
                    loading: false,
                    data: null
                }
            })
            .addCase(checkIsLoggedIn.fulfilled,(state, {payload})=>{
                return {
                    ...state,
                    loading: false,
                    data: {
                        ...payload
                    }
                }
            })
            .addCase(checkIsLoggedIn.pending,(state, {payload})=>{
                return {
                    ...state,
                    loading: true,
                    data: null
                }
            })
            
            .addCase(checkIsLoggedIn.rejected, (state, {payload})=>{
                return {
                    ...state,
                    loading: false
                }
            })
            .addCase(register.fulfilled, (state, {payload})=>{
                console.log('successSLICE');
            })
            .addCase(changePassword.rejected, (state, {payload})=>{
                return {
                    ...state,
                    passwordError: true
                }
            })
    },
});
export const selectAdminLogin = (state) => state.adminLogin.data
export const selectAdminLoading = (state) => state.adminLogin.loading
export const selectPasswordError = (state) => state.adminLogin.passwordError
export const adminLoginReducer = adminLoginSlice.reducer;
