import { createSlice } from '@reduxjs/toolkit';


const languageSlice = createSlice({
  name: 'language',
  initialState: 'arm',
  reducers: { 
    changeLanguage: (state, action) => { 
        console.log(action.payload); 
        return action.payload; 
      }
    },
});

export const { changeLanguage } = languageSlice.actions;
export const selectLanguage = state => state.language
export  const languageReducer = languageSlice.reducer;