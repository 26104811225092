import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import AdminApp from './admin/adminApp';
import DriverApp from './driver/driverApp';
import MainAdminApp from './mainAdmin/mainAdminApp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <div className="App">
          <ToastContainer  pauseOnFocusLoss={false} limit={3}/>
            <Router>
                <Routes>
                    <Route path="/admin/*" element={<AdminApp />} />
                    <Route path="/mainDiamondAppPay/*" element={<MainAdminApp />} />
                    <Route path="/*" element={<DriverApp />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
