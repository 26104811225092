import { Link } from 'react-router-dom';
import './DriverHeader.css'
import { useSelector } from 'react-redux';
import { selectDriver } from '../../../store/slices/deiverSlice/deiverSlice';


const DriverHeader = () => {
    const driver = useSelector(selectDriver)
    const driverName = driver.data?.name === 'GEVORG TERTERYAN RAZMIKI' ? 'Անուն, Ազգանուն, հայրանուն' : driver.data?.name
    return (
        <div className='DriverHeader'>
            <div className="driver">
                <span>{driverName}</span>
            </div>
            <div className="settings-icon">
                <Link to="/settings" className='SettingsLink'><i className='fa fa-cog'></i></Link>
            </div>
        </div>
    );
}

export default DriverHeader;
