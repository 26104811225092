const parkPercentages = {
    CompanyTaxi: 0.3,
    CashBack: 0.3,
    diamond: 0.3,
    TaxiEliza: 0.5,
    Alti: 0.3
}

const getPercentage = (parkName) =>{
    return parkPercentages[parkName];
}

export default getPercentage