import { useDispatch, useSelector } from 'react-redux';
import images from '../.././assets/images';
import './LanguageChange.css'
import { changeLanguage, selectLanguage } from '../../store/slices/languageSlice/LanguageSlice';

const LanguageChange = () => {
    const language = useSelector(selectLanguage)
    const dispatch = useDispatch()
    return (
        <div className='LanguageChange'>
            <div className="flagContainer">
                <img 
                    src={images.armFlag} 
                    onClick={() => dispatch(changeLanguage('arm'))} 
                    alt="English" 
                    className={language === 'arm'?'flag activeFlag':'flag'}
                    title="English" 
                />
                <span className="LanguageChangeLabels">Հայ</span>
            </div>
            <div className="flagContainer">
                <img 
                    src={images.rusFlag} 
                    onClick={() => dispatch(changeLanguage('rus'))}
                    className={language === 'rus'?'flag activeFlag':'flag'}
                    alt="Russian" 
                    title="Russian" 
                />
                <span className="LanguageChangeLabels">Рус</span>
            </div>
            <div className="flagContainer">
                <img 
                    src={images.engFlag} 
                    onClick={() => dispatch(changeLanguage('eng'))} 
                    alt="Armenian" 
                    className={language === 'eng'?'flag activeFlag':'flag'}
                    title="Armenian" 
                />
                <span className="LanguageChangeLabels">Eng</span>
            </div>
        </div>
    );
}

export default LanguageChange;
