import Transactions from "./components/Transactions";
import Transfers from "./components/Transfers";
import Dashboard from "./components/Dashboard";
import Homewrap from './pages/Homewrap/';
import { Routes, Route } from 'react-router-dom'; 
import './adminApp.css';
import DriversPage from "./components/DriversPage";
import AdminLogin from "./components/AdminLogin";
import AdminSettings from "./components/AdminSettings/AdminSettings";
import AdminBonus from "./components/AdminBonus/AdminBonus";
import AdminMessages from "./components/AdminMessages/AdminMessages";
import AdminContactUs from "./components/AdminConactUs/AdminContactUs";

function AdminApp() {
 
  return (
    <div className="AdminApp">
      <Routes>
        <Route path="login" element={<AdminLogin/>}/>
        <Route path="/" element={<Homewrap />}> 
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transfers" element={<Transfers />} />
            <Route path="drivers" element={<DriversPage />} />
            <Route path="settings" element={<AdminSettings />} />
            <Route path="bonus-system" element={<AdminBonus />} />
            <Route path="messages" element={<AdminMessages />} />
            <Route path="contact-us" element={<AdminContactUs />} />
        </Route>
      </Routes>
    </div>
  );
}

export default AdminApp;
