import { createSlice } from "@reduxjs/toolkit";
import { sendDriverTransfer } from "./driverTransferAPI";


const driverTransferSlice = createSlice({
    name: 'driverTransfer',
    initialState: null,
    reducers:{  
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendDriverTransfer.pending, (state) =>{
            })
            .addCase(sendDriverTransfer.fulfilled, (state, {payload}) => {
                return {
                    ...payload
                }
            })
            .addCase(sendDriverTransfer.rejected,(state)=>{
            })
    },
})

export const selectDriverTransfer = state => state.driverTransfer

export const driverTransferReducer = driverTransferSlice.reducer