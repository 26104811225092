import { useDispatch, useSelector } from 'react-redux';
import { selectLoginInput, setLoginInput } from '../../../store/slices/loginInputSlice/loginInputSlice';
import { checkDriver } from '../../../store/slices/loginInputSlice/loginInputAPI';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice';
import LanguageChange from '../../../sharedComponents/LanguageChange';
import DriverRegister from '../DriverRegister';
import DriverPassword from '../DriverPassword/DriverPassword';
import Logo from '../../../sharedComponents/Logo';
import './Login.css';
import { useEffect } from 'react';
import { startStopLoading } from '../../../store/slices/deiverSlice/deiverSlice';

const Login = () => {
    const dispatch = useDispatch();
    const loginInput = useSelector(selectLoginInput);
    const language = useSelector(selectLanguage);


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(checkDriver(loginInput.phoneNumber)); 
    }


    return (
        <div className="Login">
            <div className="diamondLogo">
                <Logo size='20px' color='white' />
                <h1 className='LoginDiamond'>Diamond Pay</h1>
            </div>
            <div className="LoginHeader">
                <span className='LoginHeading'>
                    {language === 'arm' ? 'Բարի Գալուստ' : language === 'rus' ? "Добро пожаловать" : 'Welcome'}
                </span>
            </div>
            <div className={language === 'rus' ? "LoginMain rusLangLoginMain" : 'LoginMain'}>
                {loginInput.isDriver === '' && (
                    <>
                        <span>
                            {language === 'arm' ? 'Մուտքագրեք ձեր հեռախոսահամարը' : language === 'rus' ? "Введите ваш номер телефона" : 'Enter your phone number'}
                        </span>
                        <form className='LoginForm' onSubmit={handleSubmit}>
                            <input
                                className={'LoginFormInput'}
                                type="tel" 
                                value={loginInput.phoneNumber} 
                                onChange={(e) => dispatch(setLoginInput({ phoneNumber: e.target.value }))} 
                            />
                            <button type="submit" className={'loginFormButton'}>
                                {language === 'arm' ? 'Մուտք' : language === 'rus' ? "Войти" : 'Log in'}
                            </button>
                        </form>
                    </>
                )}

                {loginInput.isDriver === 'not found' && (
                    <>
                        <span>
                            {language === 'arm' ? 'Հեռախոսահամարը գտնված չէ' : language === 'rus' ? "Номер телефона не найден" : 'Phone number not found'}
                        </span>
                        <form className='LoginForm' onSubmit={handleSubmit}>
                            <input 
                                className='LoginFormInputError'
                                type="tel" 
                                value={loginInput.phoneNumber} 
                                onChange={(e) => dispatch(setLoginInput({ phoneNumber: e.target.value }))} 
                            />
                            <button type="submit" className={"loginFormButton"}>
                                {language === 'arm' ? 'Փորձել կրկին' : language === 'rus' ? "Попробуйте снова" : 'Try again'}
                            </button>
                        </form>
                    </>
                )}

                {loginInput.isDriver === 'found with pass' && (
                    <DriverPassword />
                )}

                {loginInput.isDriver === 'found without pass' && (
                    <DriverRegister />
                )}
            </div>
            <LanguageChange />
        </div>
    );
}

export default Login;
