import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';

export const fetchAdminOrderList = createAsyncThunk(
    'adminOrderList/fetchAdminOrderList',
    async function ({ park_id, limit, offset, start, end, driver_id, status }) {
        // Construct the params object conditionally
        const params = {
            park_id,
            ...(limit && { limit }),           // Include `limit` if truthy
            ...(offset && { offset }),         // Include `offset` if truthy
            ...(start && { start }),           // Include `start` if truthy
            ...(end && { end }),               // Include `end` if truthy
            ...(driver_id && { driver_id }),   // Include `driver_id` if truthy
            ...(status && status.length > 0 && { status })
        };
        console.log(params);
        
        // Make the API request
        const response = await axios.get('https://api.diamondpay.am/api/park/orders/list', {
            params,
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    }
);
export const sendOrderTransfer = createAsyncThunk(
    'adminOrderList/sendOrderTransfer',
    async function ({order_id, park_id}) {
        
        const response = await axios.post(`https://api.diamondpay.am/api/park/orders/send`, {
                order_id,
                park_id
        }, 
           { headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = response.data;
        return data;
    }
);

