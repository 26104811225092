import { createSlice } from "@reduxjs/toolkit";
import { mainLogin, mainParkList, mainParkReport } from "./mainAdminLogin";

const mainAdminLogin = createSlice({
    name: 'mainAdminLogin',
    initialState: {
        data: null,
        reportData: [],
        isLoggedIn: false, 
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(mainLogin.fulfilled, (state, { payload }) => {
                return {
                    ...state,
                    data: { ...payload },
                    isLoggedIn: true, // Set to true on successful login
                };
            })
            .addCase(mainParkList.fulfilled, (state, { payload }) => {
                return {
                    ...state,
                    data: { ...payload }
                };
            })
            .addCase(mainParkReport.fulfilled, (state, { payload }) => {
                const existingIndex = state.reportData.findIndex(report => report.park_id === payload.park_id);
                if (existingIndex !== -1) {
                    state.reportData[existingIndex] = payload;
                } else {
                    state.reportData.push(payload);
                }
            });
            
    },
});

export const selectMainLogin = state => state.mainAdminLogin;
export const mainAdminReducer = mainAdminLogin.reducer;
