import "./DriverPassword.css"

import { selectLoginInput } from '../../../store/slices/loginInputSlice/loginInputSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice';
import { sendPassword } from '../../../store/slices/loginInputSlice/loginInputAPI';
import { useState } from "react";
import { languageProvider } from "../../../assets/languageProvider";


const DriverPassword = ({ value, onChange }) => {
    const [passInput, setPassInput] = useState('')
    const dispatch = useDispatch()
    const loginInput = useSelector(selectLoginInput)
    const error = loginInput.error
    const [passwordType, setPasswordType] = useState('password');
    const activeLanguage = useSelector(selectLanguage)
    const language = languageProvider({ page: 'login', lang: activeLanguage })


    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(sendPassword({ username: loginInput.phoneNumber, password: passInput }))
    }
    const handlePassInput = (value) => {
        setPassInput(value)
    }

    const handlePassVisibility = ()=>{
        if (passwordType === 'password') setPasswordType('text')
        else setPasswordType('password')
    }

    return (
        <>
            <span>
                {language.hintPassword}
            </span>
            <form className='LoginForm' onSubmit={handleSubmit}>
                <div className="registrationEyeInput">
                    <input
                        className={'driverRegisterInput'}
                        type={passwordType}
                        name="password"
                        placeholder='Ծածկագիր'
                        value={passInput}
                        onChange={(e) => handlePassInput(e.target.value)}
                        required
                    />
                    <i onClick={handlePassVisibility}
                       className={passwordType === 'password' ? 'far fa-eye driverEye' : 'far fa-eye-slash driverEye'}></i>
                </div>
                <button type="submit" className={'loginFormButton'}>
                    {language.login}
                </button>
            </form>
        </>
    )
}

export default DriverPassword
